import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import FigureImage from "public/images/enerflo-solar-contracting.png";

import HeroSection from "../../hero-section";

const title = "Solar Contracts in 3 Seconds";

const Docflo = () => (
  <div className="my-10">
    <div className="bg-navy pt-20 pb-10 transform skew-y-6">
      <HeroSection title={title} className="text-white -skew-y-6 flex-col-reverse md:flex-row">
        <HeroSection.Content className="w-full mb-10 md:pl-6 md:w-1/2 md:mb-20">
          <HeroSection.Header className="md:items-start lg:justify-start">
            <Heading tag="h2">
              <span className="font-semibold">
                Solar Contracts in <span className="text-primary font-black">3 Seconds</span>
              </span>
            </Heading>
          </HeroSection.Header>
          <HeroSection.Description className="text-gray-300">
            Throw your most difficult contracting scenarios our way and we’ll amaze you with accurate, real-time
            contract building, integrated interconnection documents, secured signing, and more. Built specifically for
            the solar industry, other signing solutions simply can’t compete. You’re welcome.
          </HeroSection.Description>
          <HeroSection.Action className="btn-white" href="/features/docflo">
            Save Time with Intelligent Contracting
          </HeroSection.Action>
        </HeroSection.Content>

        <figure className="flex-grow p-3 w-full xs:w-3/4 sm:w-3/5 md:w-1/2">
          <div className="pl-6 md:pl-0">
            <Image
              src={FigureImage}
              alt="Enerflo Docflo simplifies the complexities of solar contracting with real-time contract building, integrated interconnection documents, secured signing and more."
              layout="responsive"
              quality={100}
              placeholder="blur"
              className="crispImage"
              sizes="(min-width: 768px) 50vw, 100vw"
              lazyBoundary="400px"
            />
          </div>
        </figure>
      </HeroSection>
    </div>
  </div>
);

export default Docflo;
