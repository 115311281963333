import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import FigureImage from "public/images/enerflo-integrations.png";

import HeroSection from "../../hero-section";

const title = "Your Enerflo, Your Way";

const Integrations = () => (
  <div className="relative overflow-hidden bg-gradient-to-b from-gray-100 to-white md:pt-14 lg:pt-20">
    <HeroSection title={title} className="flex-col-reverse sm:flex-row md:pb-0">
      <HeroSection.Content className="sm:py-10 sm:pl-14 sm:w-1/2 sm:text-left">
        <HeroSection.Header className="sm:items-start">
          <Heading tag="h2">{title}</Heading>
        </HeroSection.Header>
        <HeroSection.Description>
          The best Solar Platform integrates with the tools you already love. Bring your canvassing app, design tool,
          CRM, dialer, in-house app… you get the point. With Enerflo’s robust APIs, webhooks and growing number of
          integrations, no two versions of Enerflo are the same.
        </HeroSection.Description>

        <HeroSection.Action className="btn-navy" href="/integrations">
          Learn More About Enerflo Integrations
        </HeroSection.Action>
      </HeroSection.Content>

      <div className="relative w-full sm:mr-[-5vw] sm:w-1/2 overflow-hidden sm:overflow-visible">
        <div className="h-1/2 w-full absolute bottom-0 left-0 z-[2] bg-gradient-to-b from-transparent to-gray-50 sm:hidden" />
        <figure className="relative z-[1] w-full sm:mr-[-5vw] overflow-hidden sm:overflow-visible">
          <div className="sm:mt-[-10%] mb-[-15%] sm:mb-0 sm:w-[115%]">
            <Image
              src={FigureImage}
              alt="The Enerflo Solar Platform has the most integrations in the industry."
              layout="responsive"
              quality={100}
              placeholder="blur"
              className="crispImage"
              sizes="(min-width: 768px) 50vw, 100vw"
              lazyBoundary="400px"
            />
          </div>
        </figure>
      </div>
    </HeroSection>
  </div>
);

export default Integrations;
