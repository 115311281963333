import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import FigureImage from "public/images/enerflo-solar-sales-process.png";

import HeroSection from "../../hero-section";

const title = "Close More Deals";

const SalesProcess = () => (
  <HeroSection title={title} className="flex-col-reverse md:flex-row-reverse">
    <HeroSection.Content className="md:w-1/2 lg:w-2/5">
      <HeroSection.Header className="md:items-start">
        <Heading tag="h2">{title}</Heading>
      </HeroSection.Header>
      <HeroSection.Description>
        Enerflo Sales Core gives your reps the most unified solar sales process in the industry. One platform, one
        login, one seamless experience across the entire solar customer journey.
      </HeroSection.Description>
      <HeroSection.Action className="btn-navy" href="/features/sales-process">
        See it in Action
      </HeroSection.Action>
    </HeroSection.Content>

    <figure className="p-3 md:p-6 lg:p-8 flex-grow w-full md:w-1/2 lg:w-3/5">
      <Image
        src={FigureImage}
        alt="Enerflo Sales Process unifies the solar sales experience with app integrations and leading edge technology."
        layout="responsive"
        quality={100}
        placeholder="blur"
        className="crispImage"
        sizes="(min-width: 768px) 60vw, 100vw"
        lazyBoundary="400px"
      />
    </figure>
  </HeroSection>
);

export default SalesProcess;
