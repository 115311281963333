import BiggestNamesInSolar from "components/biggest-names-in-solar";
import { SiteData } from "components/views/constants";
import { siteDataQuery } from "lib/sanity/queries";
import { getClient } from "lib/sanity/server";
import dynamic from "next/dynamic";

import Page from "../components/layout/page";
import CustomerEngagement from "../components/views/home/customer-engagement";
import Docflo from "../components/views/home/docflo";
import Integrations from "../components/views/home/integrations";
import Lendflo from "../components/views/home/lendflo";
import PowerfulIntegrations from "../components/views/home/powerful-integrations";
import SalesProcess from "../components/views/home/sales-process";

const Streamline = dynamic(() => import("../components/views/home/streamline"));
const ProjectManagement = dynamic(() => import("../components/views/home/project-management"));
const ProvenPlatform = dynamic(() => import("../components/views/home/proven-platform"));
const DemoFormSection = dynamic(() => import("../components/demo-form-section"));
const MainHero = dynamic(() => import("../components/views/home/main-hero"));

import type { NextPage } from "next";
type HomePageProps = { siteData: SiteData };

const Home: NextPage<HomePageProps> = ({ siteData }: HomePageProps) => {
  return (
    <Page
      title="YOUR Solar Platform for Residential Solar Sales and Installation"
      description="Enerflo integrates your favorite tools with leading edge technology to create a truly unified sales and fulfillment experience for Solar Dealers and Installers."
    >
      <MainHero />
      <Streamline />
      <PowerfulIntegrations />
      <Integrations />
      <SalesProcess />
      <ProjectManagement />
      <CustomerEngagement />
      <Docflo />
      <Lendflo />
      <ProvenPlatform data={siteData} />
      <BiggestNamesInSolar />
      <DemoFormSection />
    </Page>
  );
};

export default Home;

export async function getStaticProps(ctx) {
  const client = getClient(ctx.preview);
  let siteData = null;

  try {
    const data = await client.fetch(siteDataQuery);

    if (data) {
      siteData = data;
    }
  } catch (e) {
    console.error("Unable to fetch site data", e);
  }

  return {
    props: {
      siteData,
    },
  };
}
