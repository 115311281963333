import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import FigureImage from "public/images/enerflo-solar-financing.png";

import HeroSection from "../../hero-section";

const title = "Simplify Solar Financing";

const Lendflo = () => (
  <HeroSection title={title} className="flex-col-reverse md:flex-row-reverse">
    <HeroSection.Content className="md:w-2/5">
      <HeroSection.Header className="md:items-start">
        <Heading tag="h2">{title}</Heading>
      </HeroSection.Header>
      <HeroSection.Description>
        With Enerflo’s integrated lending, sales reps no longer juggle multiple logins for solar finance portals. Our
        deep integrations with the industry’s top lenders make quoting, applying, approving and signing seamless for
        all.
      </HeroSection.Description>

      <HeroSection.Action className="btn-navy" href="/features/lendflo">
        Get Your Customers Financed Fast
      </HeroSection.Action>
    </HeroSection.Content>

    <figure className="p-3 w-full sm:w-3/5">
      <Image
        src={FigureImage}
        alt="Enerflo Lendflo integrates with the top 5 solar lending companies to provide efficient financing options, applications, approvals and document signing."
        layout="responsive"
        quality={100}
        placeholder="blur"
        className="crispImage"
        sizes="(min-width: 768px) 60vw, 100vw"
        lazyBoundary="400px"
      />
    </figure>
  </HeroSection>
);

export default Lendflo;
