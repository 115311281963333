import React from "react";

import Heading from "components/heading";
import HeroSection from "components/hero-section";
import Image from "next/image";
import NuSunLogo from "public/images/logos/nusun-solar-logo.png";
import SunvenaLogo from "public/images/logos/sunvena-logo.png";
import TitaniumLogo from "public/images/logos/titanium-solar-logo.png";
import TrismartLogo from "public/images/logos/trismart-solar-logo.png";
import VivintLogo from "public/images/logos/vivint-logo.svg";

import styles from "./index.module.css";

const title = "We Proudly Serve Some Of The Biggest Names In Solar";

const BiggestNamesInSolar = () => (
  <div className="bg-gray-100">
    <HeroSection title={title}>
      <HeroSection.Content>
        <HeroSection.Header>
          <Heading tag="h5">{title}</Heading>
        </HeroSection.Header>

        <div className="flex justify-center sm:justify-between flex-wrap md:flex-nowrap -mx-2 -mt-2">
          <div className={styles.logoItem}>
            <Image src={VivintLogo} alt="Logo - Vivint Solar" />
          </div>
          <div className={styles.logoItem}>
            <Image src={TrismartLogo} alt="Logo - TriSMART Solar" />
          </div>
          <div className={styles.logoItem}>
            <Image src={NuSunLogo} alt="Logo - NuSun Solar" />
          </div>
          <div className={styles.logoItem}>
            <Image src={SunvenaLogo} alt="Logo - Sunvena" />
          </div>
          <div className={styles.logoItem}>
            <Image src={TitaniumLogo} alt="Logo - Titanium Solar" />
          </div>
        </div>
      </HeroSection.Content>
    </HeroSection>
  </div>
);

export default BiggestNamesInSolar;
