import React, { PropsWithChildren, ReactNode } from "react";

import clsx from "clsx";
import _kebabCase from "lodash/kebabCase";
import Link from "next/link";

type SubComponentProps = PropsWithChildren<{ className?: string }>;

const HeroInner = ({ children, className }: SubComponentProps) => (
  <div className={clsx("heroInner", className)}>{children}</div>
);

const HeroHeader = ({ children, className }: SubComponentProps) => (
  <div className={clsx("heroHeader", className)}>{children}</div>
);

const HeroContent = ({ children, className }: SubComponentProps) => (
  <div className={clsx("heroContent", className)}>{children}</div>
);

const HeroDescription = ({ children, className = "text-gray-500" }: SubComponentProps) => (
  <p className={clsx("heroDescription", className)}>{children}</p>
);

const HeroAction = ({ children, className, href }: SubComponentProps & { href: string }) => (
  <Link href={href}>
    <a className={clsx("heroAction btn", className)}>{children}</a>
  </Link>
);

type HeroProps = {
  className?: string;
  children: ReactNode;
  title?: string;
};

export const HeroSectionContainer = ({ title, children, className = "items-center" }: HeroProps) => (
  <section id={_kebabCase(title)} className={clsx("heroSection", className)}>
    {children}
  </section>
);

const HeroSection = ({ title, children, className }: HeroProps) => (
  <HeroSectionContainer title={title}>
    <HeroInner className={className}>{children}</HeroInner>
  </HeroSectionContainer>
);

HeroSection.Header = HeroHeader;
HeroSection.Inner = HeroInner;
HeroSection.Content = HeroContent;
HeroSection.Description = HeroDescription;
HeroSection.Action = HeroAction;

export default HeroSection;
