import Image from "next/image";
import DesignToolsIconImage from "public/images/icons/design-tools-icon.svg";
import EnerfloIntegrationsIconImage from "public/images/icons/enerflo-integrations-icon.svg";
import FinanceOptionsIconImage from "public/images/icons/finance-options-icon.svg";
import SalesQualificationIconImage from "public/images/icons/sales-qualification-tools-icon.svg";

export enum IntegrationTypes {
  Overview = "overview",
  Design = "design",
  Sales = "sales",
  Finance = "finance",
}

export type IntegrationBlockData = {
  title: string;
  description: string;
  figure: JSX.Element;
  action: { text: string; href: string };
};

export const integrationDescriptions: {
  [key in IntegrationTypes]: IntegrationBlockData;
} = {
  overview: {
    title: "Enerflo Integrations",
    description:
      "As the ONLY lead-to-PTO, open API solar platform, Enerflo has the most integrations in the industry. Do more with integrations.",
    figure: <Image src={EnerfloIntegrationsIconImage} alt="Icon - Enerflo Integrations." />,
    action: {
      text: "Learn More",
      href: "/integrations",
    },
  },
  sales: {
    title: "Compare Sales Qualification Tools",
    description:
      "Use these powerful tools with Enerflo for lead generation, management, canvassing, pipeline management and more.",
    figure: <Image src={SalesQualificationIconImage} alt="Icon - Enerflo Sales Qualification." />,
    action: {
      text: "Compare Sales",
      href: "/integrations/sales-qualification",
    },
  },
  design: {
    title: "Compare Design Tools",
    description:
      "You have a choice on Enerflo when it comes to solar PV system design -- choose your favorite tool, or bring your own.",
    figure: <Image src={DesignToolsIconImage} alt="Icon - Enerflo Design Tools." />,
    action: {
      text: "Compare Design",
      href: "/integrations/solar-design",
    },
  },
  finance: {
    title: "Compare Finance Options",
    description:
      "Whether your homeowners want to finance, pay cash or lease from a 3rd party, Enerflo has you covered with Lendflo integrated payments.",
    figure: <Image src={FinanceOptionsIconImage} alt="Icon - Enerflo Finance Options." />,
    action: {
      text: "Compare Finance",
      href: "/integrations/solar-finance",
    },
  },
};
