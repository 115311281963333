import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import BackgroundImageMobile from "public/images/enerflo-background-map-mobile.png";
import BackgroundImage from "public/images/enerflo-background-map.png";
import FigureImage from "public/images/enerflo-solar-customer-portal.png";

import HeroSection from "../../hero-section";

const title = "Engage Your Customers";

const CustomerEngagement = () => (
  <div className="relative">
    <div className="hidden sm:block">
      <Image
        src={BackgroundImage}
        alt="map background"
        layout="fill"
        className="object-center object-cover"
        lazyBoundary="400px"
      />
    </div>
    <div className="sm:hidden">
      <Image
        src={BackgroundImageMobile}
        alt="map background"
        layout="fill"
        className="object-center object-cover"
        lazyBoundary="400px"
      />
    </div>
    <HeroSection
      title={title}
      className="relative flex-col-reverse sm:flex-row-reverse h-full bg-gradient-to-b from-transparent to-white sm:bg-none"
    >
      <HeroSection.Content className="w-full z-[1] sm:text-left md:w-1/2 py-6 backdrop-filter backdrop-blur-xl firefox:bg-white firefox:bg-opacity-50 rounded-xl">
        <HeroSection.Header className="md:items-start">
          <Heading tag="h2">{title}</Heading>
        </HeroSection.Header>
        <HeroSection.Description>
          Enerflo Customer Portal keeps your solar customers in the loop by automating communication and notifications.
          Build more trust and confidence with enhanced transparency on the sales process and a better view of their
          installation.
        </HeroSection.Description>
        <HeroSection.Action className="btn-navy" href="/features/customer-engagement">
          Keep Your Customers Happy & Informed
        </HeroSection.Action>
      </HeroSection.Content>

      <figure className="flex-grow p-6 w-full xs:w-3/4 md:w-1/2">
        <Image
          src={FigureImage}
          alt="The Enerflo Customer Portal gives solar customers more transparency on the sales process and a better view on their installation."
          layout="responsive"
          quality={100}
          placeholder="blur"
          className="crispImage"
          sizes="(min-width: 768px) 50vw, 100vw"
          lazyBoundary="400px"
        />
      </figure>
    </HeroSection>
  </div>
);

export default CustomerEngagement;
