import { PropsWithChildren } from "react";

import Heading from "components/heading";
import HeroSection from "components/hero-section";

import { IntegrationBlockData } from "./integration-descriptions";

const defaultTitle = "Explore more Enerflo Integrations";

const ItemBlock = ({ title, description, action, figure }: IntegrationBlockData) => (
  <div className="border border-white rounded-lg bg-primary/30 p-4 shadow flex-1 md:p-6">
    <div className="flex flex-col grow gap-10 h-full justify-between items-center">
      <div className="text-center space-y-2">
        <figure>{figure}</figure>
        <p className="text-white text-lg md:text-xl font-semibold">{title}</p>
        <p className="text-gray-200">{description}</p>
      </div>

      <a
        href={action.href}
        className="btn btn-primary text-sm w-full whitespace-nowrap min-w-[180px] text-center xs:w-auto md:text-base"
      >
        {action.text}
      </a>
    </div>
  </div>
);

type IntegrationsGridProps = { items: IntegrationBlockData[] };

export const IntegrationsGrid = ({ items }: IntegrationsGridProps) => (
  <div className="flex flex-col flex-grow h-full gap-4 p-4 md:flex-row lg:gap-8">
    {items.map(item => (
      <ItemBlock key={item.title} {...item} />
    ))}
  </div>
);

const DefaultSectionTitle = ({ title }: { title: string }) => (
  <HeroSection.Content>
    <HeroSection.Header className="md:items-start">
      <Heading tag="h2" className="leading-tight">
        {title}
      </Heading>
    </HeroSection.Header>
  </HeroSection.Content>
);

type ExploreMoreIntegrationsProps = PropsWithChildren<{ items: IntegrationBlockData[]; title?: string }>;

const ExploreMoreIntegrations = ({ items, title = defaultTitle, children }: ExploreMoreIntegrationsProps) => {
  return (
    <div className="bg-gradient-to-br from-primary to-navy-dark md:py-10">
      <HeroSection title={title} className="text-white flex-col md:space-x-0 md:space-y-4">
        {children || <DefaultSectionTitle title={title} />}
        <IntegrationsGrid items={items} />
      </HeroSection>
    </div>
  );
};

export default ExploreMoreIntegrations;
