import React from "react";

import Heading from "components/heading";
import Image from "next/image";
import FigureImage from "public/images/enerflo-aerialytic-instant-ai-solar-designs.png";

import HeroSection from "../../hero-section";
import { IntegrationsGrid } from "../integrations/explore-integrations";
import { integrationDescriptions } from "../integrations/integration-descriptions";

const title = "Optimize Your Sales Process With Time-Saving Integrations";

const PowerfulIntegrations = () => {
  return (
    <div className="bg-gradient-to-tr from-navy-dark to-primary md:py-10">
      <HeroSection title={title} className="text-white">
        <div className="w-full space-y-20">
          <div>
            <HeroSection.Content>
              <HeroSection.Header className="text-center mb-8 lg:mb-4">
                <Heading tag="h2" className="leading-snug">
                  {title}
                </Heading>
              </HeroSection.Header>
            </HeroSection.Content>
            <div className="flex flex-col flex-grow min-w-0 md:items-center md:flex-row">
              <figure className="flex-grow p-6 w-full mx-auto sm:w-4/5 md:w-1/2">
                <Image
                  src={FigureImage}
                  alt="Lead to Proposal in 90 Seconds with Enerflo and Aerialytic Instant AI Solar Designs."
                  layout="responsive"
                  quality={100}
                  placeholder="blur"
                  className="crispImage"
                  sizes="(min-width: 768px) 50vw, 100vw"
                  lazyBoundary="400px"
                />
              </figure>
              <HeroSection.Content className="md:w-1/2">
                <div>
                  <p className="uppercase font-semibold tracking-wider text-gray-100">Featured Integration</p>
                  <HeroSection.Header className="items-start mt-0">
                    <Heading tag="h4">From Lead to Proposal in 90 Seconds</Heading>
                  </HeroSection.Header>
                </div>
                <HeroSection.Description className="text-gray-100">
                  There’s a new Solar Design Tool in town and it’s powered by AI, which means you get accurate designs
                  at a fraction of the time and cost of other design tools. Meet Aerialytic, Enerflo’s latest AI Solar
                  Design Tool integration.
                </HeroSection.Description>
                <HeroSection.Action className="btn-primary" href="/aerialytic">
                  Learn More
                </HeroSection.Action>
              </HeroSection.Content>
            </div>
          </div>
          <div>
            <HeroSection.Content>
              <HeroSection.Header>
                <Heading tag="h2" className="leading-snug">
                  Explore more Enerflo Integrations
                </Heading>
              </HeroSection.Header>
            </HeroSection.Content>
            <IntegrationsGrid
              items={[integrationDescriptions.sales, integrationDescriptions.design, integrationDescriptions.finance]}
            />
          </div>
        </div>
      </HeroSection>
    </div>
  );
};

export default PowerfulIntegrations;
